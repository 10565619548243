import React, { useEffect, useState } from "react";
import Ribbon from "../../components/Ribbon";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = () => {
  const { token } = useParams();
  const [passwordType, setPasswordType] = useState(false);
  const [password2Type, setPassword2Type] = useState(false);
  const [password, setPassword] = useState(false);
  const [password2, setPassword2] = useState(false);
  const [loading, setLoading] = useState(false);
  const apiUrl = window.location.origin.includes("localhost")
    ? "http://localhost:5000"
    : "https://khamco-shop-backend.onrender.com";
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Reset Password - Khamco Shop";
  }, []);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!password || !password2) {
      toast.info("Both fields are required");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}/users/reset-password/${token}`,
        {
          token: token,
          password: password,
          password2: password2,
        }
      );
      setLoading(false);
      toast.success(response.data.message);
      navigate("/users/login");
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || error.message);
    }
  };

  return (
    <>
      <Ribbon />
      <main className="p-[1rem] md:p-[4rem]">
        <section className="p-[4rem] grid justify-center items-center">
          <h1 className="text-center text-[2rem] font-minimoBold">Change Password</h1>
          <form
            onSubmit={handleResetPassword}
            className="flex flex-col justify-center items-center w-[90vw]"
          >
            <div className="w-full md:w-1/2 flex flex-col mx-auto my-4 relative">
              <label className="text-lg my-2 select-none self-start font-medium" for="password">New Password</label>
              <input className="p-2 border border-gray-800 rounded-md outline-none font-sans text-lg my-2 focus:border-[blue]"
                type={passwordType ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter a password"
              />
              <button
                type="button"
                className="absolute w-[12%] right-0 top-[60%] translate-y-[-50%] cursor-pointer capitalize hover:text-khamco-primary my-2"
                onClick={() => setPasswordType(!passwordType)}
              >
                {passwordType ? "Hide" : "Show"}
              </button>
            </div>
            <div className="w-full md:w-1/2 flex flex-col mx-auto my-4 relative">
              <label className="text-lg my-2 select-none self-start font-medium" for="password2">Confirm Password</label>
              <input className="p-2 border border-gray-800 rounded-md outline-none font-sans text-lg my-2 focus:border-[blue]"
                type={password2Type ? "text" : "password"}
                onChange={(e) => setPassword2(e.target.value)}
                placeholder="Enter a matching password"
              />
              <button
                type="button"
                className="absolute w-[12%] right-0 top-[60%] translate-y-[-50%] cursor-pointer capitalize hover:text-khamco-primary my-2"
                onClick={() => setPassword2Type(!password2Type)}
              >
                {password2Type ? "Hide" : "Show"}
              </button>
            </div>
            <div className="w-full md:w-1/2 flex flex-col mx-auto my-4 relative">
              <button type="submit" className="text-center text-lg bg-[blue] w-1/2 mx-auto p-4 text-white rounded-full border-none outline-none hover:bg-blue-900 cursor-pointer">
                {loading ? (
                  <i className="fas fa-spinner animate-spin"></i>
                ) : (
                  "Reset Password"
                )}
              </button>
            </div>
          </form>
        </section>
      </main>
    </>
  );
};

export default ResetPassword;
