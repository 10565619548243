import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo192.png";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleLogout = async () => {
    localStorage.clear();
    navigate("/users/login");
  };

  const apiUrl = window.location.origin.includes("localhost")
    ? "http://localhost:5000/users/language"
    : "https://khamco-shop-backend.onrender.com/users/language";
  const authKey = localStorage.getItem("jwt");

  const updateUserLanguage = async (language) => {
    try {
      i18n.changeLanguage(language);
      document.body.dir = i18n.dir();

      await axios.post(
        apiUrl,
        { language: language },
        {
          headers: { Authorization: `Bearer ${authKey}` },
        }
      );
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const handleLanguageChange = (e) => {
    const language = e.target.value;
    updateUserLanguage(language);
  };

  return (
    <nav className="flex gap-[8rem] justify-evenly items-center py-2 px-4">
      <Link
        to="/"
        className="text-[3rem] text-khamco-primary no-underline font-minimoBold"
      >
        <img src={logo} alt="logo" style={{ width: "40%" }} />
      </Link>
      {!menu && (
        <button
          className="text-khamco-ribbon text-[2rem] cursor-pointer md:hidden"
          onClick={() => setMenu(!menu)}
        >
          <i className="fas fa-bars"></i>
        </button>
      )}
      {menu && (
        <button
          className="text-khamco-ribbon text-[2rem] cursor-pointer md:hidden"
          onClick={() => setMenu(!menu)}
        >
          <i className="fas fa-times"></i>
        </button>
      )}
      <div
        className={`md:flex flex-col md:flex-row justify-between list-none gap-[2rem] absolute md:relative w-full md:w-[35%] bg-white top-[15%] z-50 py-[1rem] px-[4rem] md:p-0 ${
          menu ? "flex" : "hidden"
        }`}
      >
        <Link
          to="/"
          className="text-white text-center bg-khamco-ribbon py-2 px-4 rounded-2xl cursor-pointer text-[1rem]"
        >
          {t("Main Menu")}
        </Link>
        <form>
          <select
            value={i18n.resolvedLanguage}
            onChange={handleLanguageChange}
            className="text-white bg-khamco-ribbon py-2 px-4 rounded-2xl cursor-pointer text-[1rem] w-full"
          >
            <option
              value="en"
              className="bg-white text-khamco-secondary cursor-pointer"
            >
              {t("English")}
            </option>
            <option
              value="hi"
              className="bg-white text-khamco-secondary cursor-pointer"
            >
              {t("Hindi")}
            </option>
            <option
              value="ur"
              className="bg-white text-khamco-secondary cursor-pointer"
            >
              {t("Urdu")}
            </option>
            <option
              value="ar"
              className="bg-white text-khamco-secondary cursor-pointer"
            >
              {t("Arabic")}
            </option>
          </select>
        </form>
        <button
          className="text-white bg-[crimson] hover:bg-[red] py-2 px-4 rounded-2xl cursor-pointer text-[1rem]"
          onClick={handleLogout}
        >
          {t("Logout")} <i className="fas fa-power-off"></i>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
