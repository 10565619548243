import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import NotFound from "./pages/404";
import { Suspense, useEffect, useCallback } from "react";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStoreActions } from "easy-peasy";
import {jwtDecode} from "jwt-decode"; // Correct import
import { appRoutes } from "./routes";
import ScrollToTop from "./components/ScrollToTop";
import ForgotPassword from "./pages/users/ForgotPassword";
import ResetPassword from "./pages/users/ResetPassword";
import axios from "axios";
import { useTranslation } from "react-i18next";

function App() {
  const authKey = localStorage.getItem("jwt");
  const setUser = useStoreActions((state) => state.setUser);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  document.body.dir = i18n.dir();

  // Utility function to check if the token is expired
  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
      return Date.now() >= decoded.exp * 1000;
    } catch (error) {
      console.error("Token parsing error:", error);
      return true;
    }
  };

  // useEffect for token validation and axios interceptor
  useEffect(() => {
    const token = localStorage.getItem("jwt");

    if (token && !isTokenExpired(token)) {
      // Token is valid, set user details
      const decoded = jwtDecode(token);
      setUser({
        id: decoded.id,
        email: decoded.email,
        role: Array.isArray(decoded.role) ? decoded.role.map((r) => ({ type: r })) : [{ type: decoded.role }],
        language: decoded.language,
      });
    } else if (token && isTokenExpired(token)) {
      localStorage.clear();
      navigate("/users/login");
      toast.warn("Session expired. Please log in again.");
    }

    // Axios interceptor to handle session expiry or unauthorized requests
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.clear();
            navigate("/users/login");
            toast.warn("Session expired. Please log in again.");
          } else if (error.response.status === 403) {
            toast.warn("You are not authorized.");
            navigate("/");
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [navigate, setUser]);

  // Utility function to check if user is authenticated
  const isAuthenticated = useCallback(() => {
    return authKey && !isTokenExpired(authKey);
  }, [authKey]);

  return (
    <Suspense
      fallback={
        <div className="w-screen h-screen flex items-center justify-center">
          <i className="text-[5rem] text-khamco-ribbon fas fa-spinner animate-spin"></i>
        </div>
      }
    >
      <ScrollToTop />
      <Routes>
        {/* Public Routes */}
        <Route exact path="/users/login" element={<Login />} />
        <Route
          exact
          path="/users/forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          exact
          path="/users/reset-password/:token"
          element={<ResetPassword />}
        />

        {/* Protected Routes */}
        {appRoutes.map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            element={
              isAuthenticated() ? (
                <route.component />
              ) : (
                <Navigate to="/users/login" />
              )
            }
          />
        ))}

        {/* Misc Routes */}
        <Route exact path="/unauthorized" element={<NotFound />} />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
        autoClose={5000}
      />
    </Suspense>
  );
}

export default App;
